.wrapper {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 110px 110px 110px 110px;
  background-color: #fff;
  color: #444;
}

.gallery {
  height: 100px;
  max-height: 100px;
  width: 100px;
  max-width: 100px;
  cursor: pointer;
  object-fit: cover;
}

.box {
  background-color: lightgrey;
  color: #fff;
  border-radius: 5px;
  padding: 5px;

  margin-left: auto;
  margin-right: auto;
  display: block;
  /* font-size: 150%; */
}

.table {
  margin-bottom: 40px;
}

.rowTable {
  margin-bottom: 20px;
}

.table::after {
  content: "";
  display: block;
  width: 20%;
  /* margin: 1em auto 0; */
  margin-top: 5px;
  border-bottom: solid lightgrey;
  /* float: left; */
}

.rowTable::after {
  content: "";
  display: block;
  width: 20%;
  margin-left: 15px;
  margin-top: 5px;
  border-bottom: solid lightgrey;
  /* float: left; */
}

.react-images__footer {
  display: none !important;
}

.react-images__view-image {
  max-width: 460px !important;
  max-height: 260px !important;
}
