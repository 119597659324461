.separated {
  margin-bottom: 20px;
  /* float: left; */
  font-size: 18px;
  color: rgb(122, 122, 122);
  clear: both;
}

.colTitle {
  color: rgb(122, 122, 122);
}

.colValue {
  color: darkgrey;
}

.separated::after {
  content: "";
  display: block;
  width: 20%;
  /* margin: 1em auto 0; */
  margin-top: 5px;
  border-bottom: solid lightgrey;
  /* float: left; */
}

.brdRight {
  /* border-right: 5px solid black;
  border-image: linear-gradient(to top, #000 50%, rgba(0, 0, 0, 0) 50%);
  border-image-slice: 2; */
  display: inline-flex;
  padding-right: 5px;
  border-right: 2px solid lightgrey;
}

/* .brdRight::after {
  content: "";
  color: black;
  border-right: 1px solid black;
  font-size: 10px;
  height: 50%;
  margin-top: 10%;
  padding-left: 10px;
} */

.thumb {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin: 5px;
  border: 3px solid lightgrey;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
}
